<template>
  <div class="box">
    <headerNav title="模板库" :leftIcon="true" @leftClick="back">
      <template #rightContent v-if="isShowDown">
        <span class="down" @click="handleDown">市场</span>
      </template>
    </headerNav>
    <van-popup v-model="chooseMarketShow" position="top" style="border-radius: 0 0 8px 8px;">
      <div class="down-content">
        <p>市场<span>（多选）</span></p>
        <ul class="market-box">
          <li v-for="item in markenList" :class="item.checked ? 'item-checked' : ''" :key="item.code"
            @click="itemClick(item)">
            {{ item.name }}
            <img v-if="item.checked" :src="require('@/assets/a4.svg')" alt="">
          </li>
        </ul>
        <div class="bottom-btn">
          <div @click="reset">重置</div>
          <div @click="submit">确认</div>
        </div>
      </div>
    </van-popup>
    <div class="template-box">
      <div class="top-search">
        <van-field v-model="searchValue" placeholder="请输入模板名称" @keyup.13.native="onRefresh()" @input="inputClick">
          <template v-slot:left-icon>
            <div style="height: 100%;display: flex;align-items: center;">
              <img src="../../../assets/search.png" class="search-icon" alt="">
            </div>
          </template>
        </van-field>
        <van-button type="primary" color="#3975C6" @click="onRefresh">搜索</van-button>
      </div>
      <ul :class="hasRolePermission('mission:template:create-edit') ? 'list-box' : 'list-box list-box-l'">
        <pullRefresh ref="pullRefresh" @refresh="onRefresh">
          <van-list v-if="total" class="vant-list-style" v-model="loading" :finished="finished"
            :finished-text="total ? '·已加载全部数据·' : '·暂无数据·'" @load="onLoad">
            <li v-for="item in list" class="item-card" :key="item.id" @click="toTemplateDetails(item)">
              <div class="item-top">
                <img :src="require('@/assets/a3.svg')" alt="">
                <div class="top-title">{{ item.templateName }}</div>
                <div class="top-tag">{{ item.marketName }}</div>
              </div>
              <ul>
                <li class="l1-1">
                  <div class="number" style="color: #FB3640;">{{ item.costScale.toFixed(2) }}%</div>
                  <div class="text">广告费比例</div>
                </li>
                <li class="l1-2">
                  <div class="number" style="color: #FB3640;">{{ item.avgValue.toFixed(2) }}</div>
                  <div class="text">均单产值(元)</div>
                </li>
                <li class="l1-3">
                  <div class="number">{{ item.transferScale.toFixed(2) }}%</div>
                  <div class="text">转单率</div>
                </li>
                <li class="l2-1">
                  <div class="number">{{ item.shopScale.toFixed(2) }}%</div>
                  <div class="text">到店率</div>
                </li>
                <li class="l2-2">
                  <div class="number">{{ item.validScale.toFixed(2) }}%</div>
                  <div class="text">有效率</div>
                </li>
                <li class="l2-3">
                  <div class="number">{{ item.signScale.toFixed(2) }}%</div>
                  <div class="text">签单率</div>
                </li>
              </ul>
            </li>
          </van-list>
          <div v-else class="empty-box">
            <img :src="require('@/assets/a-empty.png')" alt="">
            <div>暂无数据</div>
          </div>
        </pullRefresh>
      </ul>
    </div>
    <div class="create-btn" v-if="hasRolePermission('mission:template:create-edit')">
      <van-button block type="info" color="#3975C6" @click="toTemplateDetails">创建模板</van-button>
    </div>
  </div>
</template>

<script>
import api from '../../../api/api.js'
import { hasRolePermission } from '../../../hooks/usePermission'

export default {
  data () {
    return {
      finished: false,
      loading: false,
      pageNum: 1, // 分页
      pageSize: 10, // 每页条数
      total: 0, // 数据总条数
      searchValue: '',
      list: [],
      markenList: [],
      chooseMarkets: [],
      chooseMarketShow: false,
      isShowDown: true
    }
  },
  methods: {
    //返回
    back () {
      this.$router.push({ name: 'TaskAdvcostMissionCenter' })
    },
    inputClick (e) {
      if (!e) this.onRefresh()
    },
    //获取模板列表
    async getTemplateList () {
      let obj = {
        limit: this.pageSize,
        page: this.pageNum,
        queryStr: JSON.stringify({
          Q_templateName_LK: this.searchValue,
          Q_marketCode_IN: this.chooseMarkets.join(',')
        })
      }
      let res = await api.getTemplateList(obj)
      if (res.code == 200) {
        this.isShowDown = res.data.isItAll
        this.total = res.count;
        if (!res.data.profitTemplateDTOS || res.data.profitTemplateDTOS.length === 0) { // 判断获取数据条数若等于0
          this.list = []; // 清空数组
          this.finished = true; // 停止加载
        } else {
          // 若数据条数不等于0
          this.list.push(...res.data.profitTemplateDTOS) // 将数据放入list中
          this.loading = false; // 加载状态结束 
          // 如果list长度大于等于总数据条数,数据全部加载完成
          if (this.list.length >= res.count) {
            this.finished = true; // 结束加载状态
          }
        }
        if (this.$refs.pullRefresh) {
          this.$refs.pullRefresh.refreshSuccess()
        }
      }
    },
    onRefresh (e) {
      this.finished = false; // 清空列表数据
      this.loading = true; // 将 loading 设置为 true，表示处于加载状态
      this.pageNum = 1; // 分页数赋值为1
      this.list = []; // 清空数组
      this.onLoad()
    },
    // 被 @load调用的方法
    onLoad (e) { // 若加载条到了底部
      let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
        // this.getList(); // 调用上面方法,请求数据
        this.getTemplateList(e)
        this.pageNum++; // 分页数加一
        this.finished && clearTimeout(timer); //清除计时器
      }, 100);
    },
    handleDown () {
      this.chooseMarketShow = !this.chooseMarketShow
    },
    // 获取市场
    async getMarketCode () {
      let params = {
        Q_typeCode_IN: 'DICT771486',
      }
      let res = await api.getDictionariesList(params)
      res.data.forEach(e => {
        if (e.status != 1) {
          this.markenList.push(e)
        }
      })
    },
    //市场选中事件
    itemClick (item) {
      this.$set(item, 'checked', !item.checked);
    },
    //市场搜索重置
    reset () {
      this.markenList.forEach((e => this.$set(e, 'checked', false)))
      this.chooseMarkets = []
      this.onRefresh()
      this.chooseMarketShow = false
    },
    //市场搜索确认
    submit () {
      this.chooseMarkets = []
      this.markenList.forEach((e => {
        if (e.checked) {
          this.chooseMarkets.push(e.code)
        }
      }))
      this.onRefresh()
      this.chooseMarketShow = false
    },
    //模板详情
    toTemplateDetails (item) {
      if (this.hasRolePermission('mission:template:create-edit')) {
        this.$router.push({
          name: 'TaskAdvcostTemplateDetails',
          params: {
            id: item.id || '0'
          }
        })
      }
    }
  },
  mounted () {

    // console.log(this.rightClick)
  },
  created () {
    this.onLoad()
    this.getMarketCode()
  },
}
</script>

<style scoped lang="scss">
.box {
  background-color: #F3F5F7;
  height: 100vh;
}

.template-box {
  margin: 8px;
  margin-bottom: 0;
}

.top-search {
  display: flex;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;

  .van-button {
    width: 85px;
    height: 40px;
  }
}



.search-icon {
  width: 22px;
}

.list-box {
  height: calc(100vh - 205px);
  overflow: auto;
  margin: 8px 0;
  // margin-bottom: 0;
  box-sizing: border-box;
}

.list-box-l {
  height: calc(100vh - 110px);

}

.list-box {
  .item-card {
    padding: 8px;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 8px;

    .item-top {
      display: flex;

      img {
        width: 25px;
        height: 25px;
      }

      .top-title {
        font-size: 19px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #333333;
        width: 65%;
        text-align: left;
      }

      .top-tag {
        padding: 0 4px;
        background: rgba(14, 139, 255, 0.1);
        border-radius: 4px;
        font-size: 17px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #0E8BFF;
        margin-left: auto;
        height: fit-content;
        line-height: 25px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      background-color: #F8F8F8;
      padding-bottom: 9px;
      margin-top: 10px;

      li {
        width: 33.33%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        .number {
          font-size: 19px;
          font-family: Bahnschrift;
          font-weight: 400;
          color: #333333;
        }

        .text {
          margin-top: 4px;
          font-size: 15px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }

      .l1-1,
      .l1-2,
      .l2-1,
      .l2-2 {
        &::after {
          position: absolute;
          content: '';
          width: 1px;
          height: 100%;
          border-right: 1px solid #EEEEEE;
          right: 0;
        }
      }

      .l1-1,
      .l1-2,
      .l1-3 {
        &::before {
          position: absolute;
          content: '';
          width: 80%;
          height: 1px;
          border-bottom: 1px solid #EEEEEE;
          bottom: 0;
          right: 0;
        }
      }

      .l1-2 {
        &::before {
          width: 100%;
        }
      }

      .l1-3 {
        &::before {
          right: none;
          left: 0;
        }
      }
    }
  }

  .empty-box {
    img {
      width: 100%;
      margin-top: 50%;
    }

    div {
      font-size: 15px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #5E5E5E;

    }
  }
}

.down {
  position: relative;
  padding-right: 15px;

  &::after {
    position: absolute;
    top: 50%;
    right: -4px;
    margin-top: -5px;
    border: 4px solid;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    // opacity: 0.8;
    content: '';
  }

}

.down-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;

}

.down-content {
  padding-top: 50px;
  background: #FFFFFF;
  overflow: hidden;

  &>p {
    margin: 0;
    text-align: left;
    line-height: 40px;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;
    padding: 0 15px;

    span {
      color: #999999;
    }
  }
}

.market-box {
  display: flex;
  flex-wrap: wrap;
  gap: 17px 8px;
  padding: 0 15px;
  padding-bottom: 20px;


  li {
    width: 23%;
    height: 35px;
    background: #F6F6F6;
    border-radius: 4px;
    line-height: 35px;
    text-align: center;
    background: #F6F6F6;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  .item-checked {
    background-color: #E8F1FF;
    color: #0E8BFF;
    position: relative;

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 21px;
    }
  }
}

.bottom-btn {
  display: flex;
  border-top: 1px solid #EEEEEE;

  div {
    flex: 1;
    font-size: 19px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    text-align: center;
    line-height: 63px;

    &:first-child {
      color: #333333;
      border-right: 1px solid #EEEEEE;
    }

    &:last-child {
      color: #3DA2FF;
    }
  }
}

.create-btn {
  background: #FFFFFF;
  display: flex;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 12px;
  align-items: center;
  padding-bottom: 20px;
  height: 90px;

  button {
    border-radius: 4px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }

  .van-button {
    width: 100%;
  }
}
</style>
<style lang="scss">
.template-box {
  .van-cell {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }
}
</style>